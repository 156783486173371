<template>
  <div
    v-loading="loading"
    class="w-full flex flex-1 flex-col"
  >
    <AppEmptyPage v-if="!socialSignatures.length">
      <template #title>You have not added any social signatures yet</template>
    </AppEmptyPage>

    <template v-else>
      <!-- Header -->
      <div class="flex justify-between items-center pb-8 border-b-2 border-gray-200">
        <h3 class="text-base md:text-18 leading-tight font-bold font-heading text-gray-800 truncate pr-16">
          Social signatures
        </h3>
      </div>

      <div class="w-full flex flex-1 flex-col overflow-y-auto">
        <SocialSignatureItemRow
          v-for="socialSignature in socialSignatures"
          :key="socialSignature.id"
          :social-signature="socialSignature"
          @onEditClick="onEditClickHandler"
        />

        <AppPagination
          class="py-16"
          :current-page="page"
          :total="total"
          @updateCurrentPage="onUpdatePageHandler"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import { useSocialSignatures, usePagination } from '@/core/compositions'
import { notification } from '@/core/helpers'
import { routeNames } from '@/router'

import AppEmptyPage from '@/components/stateless/layout/AppEmptyPage.vue'
import AppPagination from '@/components/stateless/AppPagination.vue'

import SocialSignatureItemRow from './components/SocialSignatureItemRow.vue'

export default defineComponent({
  name: 'SocialSignatures',

  components: {
    AppEmptyPage,
    AppPagination,

    SocialSignatureItemRow
  },

  setup () {
    const router = useRouter()
    const { socialSignatures, getSocialSignatures } = useSocialSignatures()
    const {
      page,
      total,
      size,
      onUpdatePage,
      onUpdateTotal,
      setPaginationFromQuery
    } = usePagination(routeNames.resources)

    const loading = ref(true)

    onMounted(() => {
      setPaginationFromQuery()
      getInitialData()
    })

    const getInitialData = async () => {
      loading.value = true

      try {
        const result = await getSocialSignatures({ page: page.value, size: size.value })

        onUpdateTotal(result.count)
      } catch (e) {
        console.error(e)
        notification()
      } finally {
        loading.value = false
      }
    }

    const onEditClickHandler = (socialSignature) => {
      router.push({ name: routeNames.editSocialSignatures, params: { platform: socialSignature.platform } })
    }

    const onUpdatePageHandler = (page) => {
      onUpdatePage(page)

      getInitialData()
    }

    return {
      routeNames,
      loading,
      socialSignatures,
      onEditClickHandler,

      // Pagination
      page,
      total,
      onUpdatePageHandler
    }
  }
})
</script>
